import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from "../../Views/Home";
import Admin from "../../Views/Admin";
import WhatsNew from '../../Views/WhatsNew';
import Post from '../../Views/Post';
import ApiCall from "../../Views/ApiCall";
import InternalDocumentStorage from "../../Views/InternalDocumentStorage/InternalDocumentStorage.js";
import DocumentHistory from "../../Views/DocumentHistory/DocumentHistory.js";
// import VeracityApiCall from "../../Views/VeracityApiCall";
import './Routes.css';
import Users from '../../Views/Admin/Users';
import Clients from '../../Views/Admin/Clients';
import Services from '../../Views/Admin/Services';
import NewPost from '../../Views/Admin/NewPost';
import ManagePost from '../../Views/Admin/ManagePost';

function Routes(props) {
    return (
        <div className="centralize">
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/admin" component={Admin} />
                <Route exact path="/admin/users" component={Users} />
                <Route exact path="/admin/services" component={Services} />
                <Route exact path="/admin/clients" component={Clients} />
                <Route exact path="/admin/newpost" component={NewPost} />
                <Route exact path="/admin/manageposts" component={ManagePost} />
                <Route exact path="/whatsnew" component={WhatsNew} />
                <Route exact path="/posts/:id" component={Post} />
                <Route exact path="/admin/updateimageurl" component={ApiCall} />
                <Route exact path="/admin/updateusertype" component={ApiCall} />
                <Route exact path="/admin/updateblobversion" component={ApiCall} />
                <Route exact path="/InternalDocumentStorage" component={InternalDocumentStorage} /> 
                <Route exact path="/InternalDocumentStorage/history" component={DocumentHistory} /> 
                {/* <Route path="/apicall" component={ApiCall} />
                <Route path="/veracityapicall" component={VeracityApiCall} /> */}
            </Switch>
        </div>
    )
}

export default Routes;