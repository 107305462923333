import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SideNav from "../../Components/SideNav";
import ClientTabs from "../../Components/ClientTabs";
import "./Home.css";
import { MyProductsProvider } from "../../Context/MyProductsContext";
import axios from "axios";

function Home() {
  const [selectedTab, setSelectedTab] = useState();
  const handleTabSelect = (eventKey, event) => {
    console.log("eventkey:", eventKey);
    console.log("event:", event);
    setSelectedTab(eventKey);
  };

  useEffect(() => {
    axios
      .get("/api/v1/Products/My", { withCredentials: true })
      .then(function (response) {
        if(response.data.clients.length > 0){
        setSelectedTab(response.data.clients[0].name);
      }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
      });
  }, []);
  return (
    <MyProductsProvider>
      <>
        <div className="container-fluid max-width" style={{marginTop: '1.5rem'}}>
          <div className="row h-100"> 
            {/*<nav className="col-auto sidebar p-0">*/}
            {/*  <SideNav selectedTab={selectedTab} />*/}
            {/*</nav>*/}
            <div className="col pb-5 mx-auto">
              <h2 className="mt-4">My Services</h2>
              <ClientTabs handleTabSelect={handleTabSelect} />
            </div>
           </div> 
        </div>
      </>
    </MyProductsProvider>
  );
}

export default Home;
