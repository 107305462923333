export interface User{
    id?: any
    displayName?: string
    title?: string
    company?: string
    email?: string
    userType?: string
    role?: string
    desiredClient?: string
  }

export interface VapUser{
    id?: any
    name?: string
    email?: string
    entities?: Entity[]
    localEntities?: Entity[]
    status?: string
}

export interface Entity{
    entityDisplayName?: string
}

export interface UserGroup{
    id?: any
    name?: string
    client?: string
    services?: string
}

export interface RefProperty{
    id?: any
    name?: string
    link?: string
}

export interface Post{
    id?: any
    category?: string
    title?: string
    createdOn?: Date
}

export interface PageProperties{
    totalCount?: number
    currentPage: number
    onClick: (p: number) => void
    onPageSizeChange: (p: number) => void
}

export interface ValidationError{
    id: string
    value: string
}

export interface InternalDocumentStorage {
    id: string
    title: string
    modifiedDate: string
    size: string
    fileName: string
    downloadLink: string
    category:string
}

export interface AuditLogVM {
    id: string
    createdBy: string
    fileName: string
    clientId: string
    dateTime: Date
    title: string
    userId:string
    userName: string
    operationType: OperationTypes
}

export enum OperationTypes{
    add, 
    update, 
    delete
}